/*
 ***********
 Create the site explorer and handle the navigation through the tree
 ***********
*/

/* eslint no-bitwise: ["error", { "allow": ["^", ">>"] }] */
/* eslint no-plusplus: 0 */

const classNames = {
  btnCollapse: 'haiku-tree-node__collapse',
  btnExpand: 'haiku-tree-node__expand',
  btnLoadMore: 'haiku-tree-node__load-more',
  tree: 'haiku-tree-list',
  node: 'haiku-tree-node',
  nodeTitle: 'haiku-tree-node__title'
};

const icons = {
  expand: '<i class="glyphicon-chevron-right"></i>',
  collapse: '<i class="glyphicon-chevron-down"></i>',
  loading: '<i class="glyphicon-spin animate-spin"></i>'
};

const api = '@@API/haiku-core-api/sitemap';

const getApiUrl = (path = '') => `${api}${path.length ? '?path=' : ''}${path}`;

/**
 * Generate a guid for div containers
 * @return {String} The GUID
 */
const guid = () => {
  // https://gist.github.com/jed/982883
  const b = a =>
    a
      ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
      : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
  return b();
};

/**
 * A quick helper that creates a jQuery element of a certain type.
 * @param  {String} type     The type of HTML element to create.
 * @param  {Object} attrs     A set of attributes to attach to the element.
 * @param  {Array}  innerEls  Any inner element to append to this new element.
 * @return {Object}          The new jQuery element.
 */
const createElement = (type = 'li', attrs = {}, innerEls = []) => {
  const element = $(`<${type} />`, attrs);
  [...innerEls].forEach(el => {
    element.append(el);
  });

  return element;
};

/**
 * Create the list expander button.
 * @param  {String} targetId The ID of the list to expand.
 * @param  {String} path    The path URL that will be called when the user clicks the button.
 * @return {Object}        The jQuery button.
 */
const createExpander = (targetId, path = '') =>
  createElement('a', {
    class: classNames.btnExpand,
    'data-expand': `#${targetId}`,
    'data-path': path,
    href: '#',
    html: icons.expand
  });

/**
 * Get the items from the server.
 * @param  {String} url  The URL to get the nodes from.
 * @param  {String} path Any path to append to the URL.
 * @return {Object}      A promise object.
 */
const getNodes = (url, path = '') =>
  $.ajax({
    url: url || getApiUrl(path)
  }).fail(err => console.log(err));

/**
 * We insert the expander and the children right into the HTML that comes from the server.
 * @param {String} html     The HTML of the element as it comes from the server.
 * @param {Object} expander The jQuery Object of the expand anchor tag.
 * @param {Object} children The jQuery Object of children nodes.
 * @return {String}         The new HTML of the node
 */
const withExpanderAndChildren = (html, expander, children) => {
  const titlePattern = `<div class="${classNames.nodeTitle}">`;
  const nodePattern = `<li class="${classNames.node}">`;
  let nodeHtml;

  nodeHtml = html.replace(
    titlePattern,
    `${titlePattern}${expander[0].outerHTML}`
  );
  nodeHtml = nodeHtml.replace(
    nodePattern,
    `${nodePattern}${children[0].outerHTML}`
  );

  return nodeHtml;
};

/**
 * Creates the load more button with the correct classes and attributes.
 * @param  {String} containerId The ID of the container where new nodes will be appended to.
 * @param  {String} href       The URL where to get new nodes from.
 * @return {Object}            The button as a jQuery element.
 */
const createLoadMoreButton = (containerId, href) =>
  createElement('a', {
    class: `${classNames.btnLoadMore} btn btn-sm btn-default`,
    'data-append-to': `#${containerId}`,
    href,
    text: `Load more`
  });

/**
 * Create a node with the correct inner elements.
 * @param  {Object} item The item as it comes from the API.
 * @return {Object}      The node as a jQuery element.
 */
const createNode = item => {
  const id = `haiku-tree-${guid()}`;

  let expander;
  let childrenContainer;
  let nodeHtml = item.html.replace(/\r?\n|\r/g, '');

  if (item.children) {
    expander = createExpander(id, item.path);
    childrenContainer = createElement('ul', {
      id,
      class: classNames.tree,
      css: { display: 'none' }
    });

    nodeHtml = withExpanderAndChildren(nodeHtml, expander, childrenContainer);
  }

  return nodeHtml;
};

/**
 * Append nodes to a container.
 * @param  {Object} container The jQuery element to append the nodes to.
 * @param  {Array}  nodes    The nodes to append to the container.
 * @return {Object}         The container with the nodes appended to it.
 */
const appendNodes = (container, nodes) => container.append(nodes);

/**
 * We need to append the correct page query param to the URL.
 * @param {String}  url  The url, duh
 * @param {Number}  page The current page, which will be incremented
 * @return {String}      The new URL with the page query string
 */
const paged = (url, page) => {
  const pageRegex = RegExp('&page=');
  const isPaged = pageRegex.test(url);
  console.log(page);
  return isPaged
    ? url.replace(/(page=)[^&]+/, `$1${page + 1}`)
    : `${url}&page=${page + 1}`;
};

/**
 * Append nodes to a tree list.
 * @param  {String}  url         The URL to call in order to get the nodes.
 * @param  {Object}  container   The list container as a jQuery element.
 * @return {Object}              The promise object.
 */
const appendToTree = (url, container) =>
  getNodes(url).done(data => {
    if (data.items) {
      let loadMoreButton;

      if (data.next) {
        const moreUrl = paged(url, data.page);
        loadMoreButton = createLoadMoreButton(container.attr('id'), moreUrl);
      }

      appendNodes(
        container,
        [...data.items.map(createNode), loadMoreButton].filter(Boolean)
      );
    }

    if (data.msg) {
      const span = createElement('span', {
        class: 'details',
        text: data.msg
      });

      const msg = createElement('li', { css: { margin: 0, padding: 0 } }, span);
      appendNodes(container, msg);
    }
  });

/**
 * Toggle the class and HTML for the button
 * from expand to collapse and viceversa.
 * @param  {Object} el The jQuery object to toggle classes and HTML for.
 * @return {void}
 */
const toggleExpandCollapse = el => {
  el.toggleClass(classNames.btnCollapse).toggleClass(classNames.btnExpand);

  const html = el.hasClass(classNames.btnCollapse)
    ? icons.collapse
    : icons.expand;

  el.html(html);
};

/**
 * Toggle the visibility of a list.
 * @param  {String} listId The listID to show or hide.
 * @return {Object}       The list with the provided ID.
 */
const toggleVisibility = listId => $(listId).toggle();

/**
 * Adds the loading icon to the inner html of the element.
 * @param  {Object} el   The jQuery element to add the loading indicator to.
 * @param  {String} html  Any other HTML to append to the element after the loading indicator.
 * @return {Object}      The jQuery element with the correct inner HTML.
 */
const toggleLoading = (el, html = '') => el.html(`${icons.loading} ${html}`);

/**
 * Set a skip refetch attribute on a list so that
 * the next time the user wants to expand the list
 * we won't call the server.
 * @param  {Object} el The list as a jQuery element.
 * @return {Object}    The same object passed in.
 */
const setSkipRefetch = el => el.attr('data-no-refetch', 1);

/**
 * Expand the node when the user clicks on it.
 * @param  {Object} e The click event.
 * @return {void}
 */
const handleExpand = e => {
  e.preventDefault();

  const expander = $(e.currentTarget);
  const node = expander.closest(`.${classNames.node}`);
  const childrenContainer = $(expander.attr('data-expand'));
  const path = expander.attr('data-path');
  const url = getApiUrl(path);

  if (!node.attr('data-no-refetch')) {
    toggleLoading(childrenContainer);
    childrenContainer.empty();
    appendToTree(url, childrenContainer).then(() => {
      setSkipRefetch(node);
      toggleVisibility(childrenContainer);
      toggleExpandCollapse(expander);
    });
  } else {
    toggleVisibility(childrenContainer);
    toggleExpandCollapse(expander);
  }
};

/**
 * Collapse the node when the user clicks on it.
 * @param  {Object} e The click event.
 * @return {void}
 */
const handleCollapse = e => {
  e.preventDefault();

  const expander = $(e.currentTarget);
  const childrenContainer = $(expander.attr('data-expand'));
  toggleVisibility(childrenContainer);
  toggleExpandCollapse(expander);
};

/**
 * Load more items when the user clicks on it.
 * @param  {Object} e The click event.
 * @return {void}
 */
const handleLoadMore = e => {
  e.preventDefault();

  const btn = $(e.currentTarget);
  const url = btn.attr('href');
  const container = $(btn.attr('data-append-to'));

  btn.addClass('disabled');
  toggleLoading(btn, btn.text());

  appendToTree(url, container).then(() => btn.remove());
};

/**
 * Bind all the required click events.
 */
$(document).on('click', `.${classNames.btnExpand}`, handleExpand);
$(document).on('click', `.${classNames.btnCollapse}`, handleCollapse);
$(document).on('click', `.${classNames.btnLoadMore}`, handleLoadMore);

// Where are we placing the sitemap on the page?
const explorerContainer = $('#site-map');

/**
 * The root of the sitemap
 * @type {Object}
 */
const explorer = createElement('ul', {
  id: `haiku-tree-${guid()}`,
  class: classNames.tree
});

// If we have a sitemap container, then let's start everything up!
if (explorerContainer.length) {
  const rootUrl = getApiUrl();

  // Let's make sure the container is empty
  explorerContainer.empty().append(explorer);

  // Create the first level of the tree
  appendToTree(rootUrl, explorer);
}
