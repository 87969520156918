/*
 ***********
 Special navigation portlets
 ***********
*/
if (jQuery().navvy) {
  const navigationPortlet = $('.navigation-portlet');
  navigationPortlet.navvy({
    type: navigationPortlet.attr('data-navigation-type')
  });

  $('.nav-style-accordion')
    .find('.panel-body')
    .navvy({
      type: 'accordion',
      allowTopLinks: false,
      arrows: {
        collapsed: '<i class="glyphicon-chevron-up"></i>',
        collapse: '<i class="glyphicon-chevron-down"></i>'
      }
    });
}
