/**
 * Define the filters via a namespace
 */

if (!$.haikuFilters) {
  $.haikuFilters = {};
}

$.haikuFilters.inArray = (array, value) => array.indexOf(value) !== -1;

$.haikuFilters.containsString = (haystack, needle) =>
  haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1;

$.haikuFilters.isEqual = (a, b) => a === b;

$.haikuFilters.getFilter = (key, value, funcType) => item =>
  $.haikuFilters[funcType](item[key], value);

$.haikuFilters.applyFilter = (
  list,
  itemKey,
  filterKey,
  filterFuncType,
  value,
  length = 0,
  withReset
) => {
  const filter = $.haikuFilters.getFilter(itemKey, value, filterFuncType);

  if (value.length > length) {
    $.data(list, 'plugin_haikuList').filter(
      { id: filterKey, filter, value },
      withReset
    );
  } else {
    $.data(list, 'plugin_haikuList').resetFilter(filterKey);
  }
};
