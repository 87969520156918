/*
 ***********
 Append Flickr JS to album links
 ***********
*/
const embedClass = 'flickr-container'; // this needs to change for the new bootstrap
const sources = '[data-flickr-embed="true"]';

$('.rich-text')
  .find('a')
  .filter(sources)
  .wrap(`<div class="${embedClass}" />`)
  .append(
    '<script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>'
  );
