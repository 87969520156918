/*
 ***********
 Turn header links into dropdown if there are more than X links
 ***********
*/
const container = $('#site-actions ul').not('.nav-down');
const maxLinks = 6;
const links = container.children('li:not(".disabled")');
const createSelect = () => {
  const select = $('<select/>', {
    style: 'width: 150px'
  });
  const defaultOption = $('<option/>', {
    value: '',
    text: 'Choose action...'
  });

  defaultOption.appendTo(select);

  for (let i = 0; i < links.length; i++) {
    const link = $(links[i]).find('a');
    const option = $('<option/>', {
      value: link.attr('href'),
      text: link.text()
    });

    select.append(option);
  }

  select.appendTo(container);

  select.on('change', e => {
    window.location.href = $(e.currentTarget).val();
  });
};

if (links.length > maxLinks) {
  links.hide();
  createSelect();
}
