/*
 ***********
 Truncate various things around the site
 ***********
*/
const items = $('*[data-truncate="yes"]');
const tabs = $('a[data-toggle="tab"]');

const truncate = () => {
  $.each(items, (idx, item) => {
    const $item = $(item);
    const lines = $item.attr('data-truncate-lines');

    $item.trunk8({ lines });
  });
};

if (jQuery().trunk8) {
  // truncate on load
  truncate();

  // Handle window resize events
  $(window).resize(() => {
    truncate();
  });

  // Handle text being loaded from tabs
  tabs.on('click', () => {
    setTimeout(truncate, 100);
  });
}
