/*
 ***********
 Handle Table Galleries with Magnific Popup
 ***********
*/
const gallery = $('table.table-simple-gallery');
const imgWithPopup = $('.image-modal');
const richTextImageModals = $('body.richtext-modals')
  .find('#site-content-body')
  .children('.rich-text')
  .find('img')
  .not(function notNestedInAnchor() {
    return $(this).closest('a').length > 0;
  });
const imageSize = 'w760';
const parseImageSource = (string, scale) => {
  const position = string.search('@@images');
  const imageUrl = `/@@images/image/${scale || ''}`;
  const src =
    position !== -1
      ? `${string.slice(0, position)}${imageUrl}`
      : `${string}${imageUrl}`;
  return src;
};
const getItemDescription = el => {
  const title = el.attr('title') || '';
  const description = el.attr('alt') || '';
  return title === description
    ? title
    : `${title}<small>${description}</small>`;
};
const getImagePopupTitle = (img, ...additional) => {
  let description = getItemDescription(img);

  if (additional) {
    description = `${description}<small>${additional.join(' ')}</small>`;
  }

  return description;
};

const zoomOptions = {
  zoom: {
    enabled: true,
    duration: 500,
    easing: 'ease-in-out'
  }
};
const galleryOptions = {
  gallery: {
    enabled: true
  },
  ...zoomOptions
};
const itemOptions = {
  type: 'image',
  image: {
    titleSrc: item => {
      const $el = $(item.el);
      const copyright = $el.attr('data-copyright')
        ? `&copy; ${$el.attr('data-copyright')}`
        : '';
      return getImagePopupTitle($el, copyright);
    }
  },
  callbacks: {
    elementParse: item => {
      // We need to modify the src element because we don't have a regular markup
      // such as <a href="IMG_SRC" /> for magnific popup to pick up
      item.src = parseImageSource(item.el.attr('src'), imageSize); // eslint-disable-line no-param-reassign
    }
  }
};

if (jQuery().magnificPopup) {
  gallery.each((_, el) => {
    $(el).magnificPopup(
      $.extend(galleryOptions, {
        ...itemOptions,
        delegate: 'img'
      })
    );
  });

  richTextImageModals.magnificPopup({
    ...zoomOptions,
    ...itemOptions,
    callbacks: {
      ...itemOptions.callbacks,
      elementParse: item => {
        item.src = $(item.el).attr('data-src'); // eslint-disable-line no-param-reassign
      }
    }
  });

  imgWithPopup.magnificPopup({
    ...zoomOptions,
    image: {
      titleSrc: item => {
        const $el = $(item.el);
        const img = $el.children('img');
        const copyright = $el.children('cite').text();
        return getImagePopupTitle(img, copyright);
      }
    },
    callbacks: {
      elementParse: item => {
        item.src = `${item.src}/@@images/image/${imageSize}`;
      }
    },
    type: 'image'
  });
}
