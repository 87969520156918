/*
 ***********
 Set up jobs feed accordion
 ***********
*/
$('.job-feeds').each((idx, el) => {
  const $el = $(el);

  if ($el.length) {
    $el.queryJobsFeed({
      feedUrl: $el.attr('data-feed-url')
    });
  }
});
