// These are specific scripts that should be moved to their appropriate places

/*
 ***********
 Turn site-search and site-actions into dropdowns activated on click - related to OMD-2700
 ***********
*/
// To be moved to the theme specific js files
const makeDropdowns = () => {
  const revealedClassName = 'revealed';
  const activeClassName = 'active';
  const toRevealClass = '.to-reveal';
  const dropdowns = $('#icon-dropdowns');
  const items = dropdowns.children('li');
  const reveal = items.children(toRevealClass);
  const buttons = items.children('a');

  reveal.removeClass('hide').hide();

  // If there is nothing to reveal on a click, let's hide the button
  // related to HSD-2178 & HSD-2213
  $.each(items, (_idx, el) => {
    const item = $(el);
    const hasChildren = item.first(`div${toRevealClass}`).children().length;
    if (!hasChildren) {
      item.children('a.dropdown').hide();
    }
  });

  buttons.on('click', e => {
    e.preventDefault();

    const $this = $(e.currentTarget);
    const toReveal = $this.siblings(toRevealClass);

    if (!toReveal.hasClass(revealedClassName)) {
      reveal.hide().removeClass(revealedClassName);
      buttons.removeClass(activeClassName);
      $this.addClass(activeClassName);
      toReveal.slideDown().addClass(revealedClassName);
    } else {
      $this.removeClass(activeClassName);
      toReveal.slideUp().removeClass(revealedClassName);
    }
  });
};

makeDropdowns();

/*
 ***********
 Match the heights of Our Team page portlet to the rest of the page
 ***********
*/
// Move this to the specific portlets
const matchHeights = () => {
  if (jQuery().listScroll) {
    const portletTeam = $('#site-content-sidebar')
      .find('.portlet-team')
      .find('.team-list');
    const coverTeam = $('.cover-team-tile').find('.team-list');
    const sidebarPortletTeam = $('.portlet-team').find('.team-list');

    const initScroll = (el, height) => {
      if (el.attr('data-profiles-limit')) {
        el.listScroll({
          limitType: 'count;',
          listCount: el.attr('data-profiles-limit'),
          bottomShadowClass: 'none'
        });
      } else {
        const firstProfileHeight = el
          .children('li')
          .first()
          .outerHeight();
        const listHeight =
          height > firstProfileHeight ? height : firstProfileHeight;

        el.listScroll({
          limitType: 'height',
          listHeight,
          bottomShadowClass: 'none'
        });
      }
    };

    const applyListScroll = () => {
      const siteContentHeight = $('#site-content-body').outerHeight(true);
      const aboveBodyContentHeight = $('#above-page-body').outerHeight(true);
      const contentHeight = siteContentHeight + aboveBodyContentHeight;

      portletTeam.each((_idx, el) => {
        initScroll($(el), siteContentHeight);
      });

      sidebarPortletTeam.each((_idx, el) => {
        initScroll($(el), contentHeight);
      });

      coverTeam.each((_idx, el) => {
        const $el = $(el);
        const limit = $el.attr('data-profiles-limit') || 0;

        $el.listScroll({
          limitType: 'count',
          listCount: limit,
          bottomShadowClass: 'shadow-bottom'
        });
      });
    };

    setTimeout(() => {
      // There is a small delay before the styles are loaded
      // where the height is different than what it should be
      // related to HSD-1246
      // When caching is enabled, the images don't always load before the script runs
      // related to OMD-2797
      applyListScroll();
    }, 1000);

    $(window).resize(() => {
      applyListScroll();
    });
  }
};

matchHeights();

/*
 ***********
 Position Research Group quotes correctly
 Move this to research group templates
 ***********
*/
$('body.portaltype-researchgroup #group-about blockquote').each((_, el) => {
  const $el = $(el);
  $el
    .siblings('p')
    .last()
    .prev()
    .before($el);
});

/*
 ***********
 Long fields link wrapping, includes profile email address and website URL
 This needs to be moved to cover pages after we remove old profiles
 ***********
*/
const maskLongFields = () => {
  const coverProfileTile = $('.cover-profile-tile');
  const profileTile = $('body.userrole-anonymous').find('.tile.tile-contact');

  const maskFields = container => {
    const fields = [
      {
        cell: container.find('tr.email'),
        text: 'Click to send email'
      },
      {
        cell: container.find('tr.pa-email'),
        text: 'Click to send email'
      },
      {
        cell: container.find('tr.website'),
        text: 'Click to open URL'
      }
    ];
    const maxWidth = container.find('.box-featured').width();

    fields.forEach(field => {
      if (field.cell.width() > maxWidth) {
        field.cell.find('a').text(field.text);
      }
    });
  };

  [coverProfileTile, profileTile].forEach(container => {
    if (container.length) {
      maskFields(container);
    }
  });
};

maskLongFields();
