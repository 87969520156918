(() => {
  const getRandomIndex = (min, max) =>
    Math.ceil(Math.random() * (max - min) + min);

  const haikus = [
    "we're very sorry<br/>this webpage could not be found<br/>we're working on it",
    'pages everywhere<br/>we tried as hard as we could<br/>to locate this one',
    'pages left and right<br/>just this one cannot be found<br/>trust us, we did try',
    "riddle me this now<br/>where could this page really be<br/>we don't really know",
    'on your monitor<br/>only real pages show<br/>this one is missing',
    'behold! an old page!<br/>you cannot click it away<br/>it seems you are lost',
    'no one came this way<br/>but you! are you lost right now?<br/>let us guide your actions',
    "a lonely feeling<br/>is all around this webpage<br/>can't find one like it"
  ];

  const getRandomHaiku = () => {
    const random = getRandomIndex(0, haikus.length);
    return haikus[random] ? haikus[random] : haikus[0];
  };

  const setHaikuContainerContent = () => {
    $('#random-haiku').html(getRandomHaiku());
  };

  $(document).ready(setHaikuContainerContent);
})();
