/*
 ***********
 Handle saving as PDF
 ***********
*/

const API_URL =
  'https://pkg7axv4xl.execute-api.eu-west-2.amazonaws.com/dev/pdf/html';
// const API_URL = 'http://localhost:3000/pdf/html';
const hostUrl = `${window.location.protocol}//${window.location.host}`;

const pdfButton = $('button[data-action="save-pdf"]');

const setupPayload = (id, filename = 'Document.pdf', baseUrl = hostUrl) => {
  const domElement = $(`#${id}`);
  const payload = {
    filename
  };

  if (domElement.length > 0) {
    payload.html = domElement.html();
    // FIXME: We need to also figure out how we can add the theme-specific stylesheet
    payload.styleUrls = [
      `${baseUrl}/reset.css`,
      `${baseUrl}/portlets.css`,
      `${baseUrl}/forms.css`,
      `${baseUrl}/print.css`,
      `${baseUrl}/++theme++bootstrap/css/bootstrap.css`,
      `${baseUrl}/++resource++collective.cover/css/cover.css`,
      `${baseUrl}/++resource++collective.cover/css/cover.css`,
      `${baseUrl}/++resource++haiku.profiles.css`,
      `${baseUrl}/++resource++haiku.core.css/haiku.core.css`,
      `${baseUrl}/++theme++bootstrap/css/screen.css`
    ];
  } else {
    throw new DOMError(`Cannot find element with id ${id}`);
  }

  return payload;
};

const ensurePdfExt = filename =>
  filename.split('.').pop() === 'pdf' ? filename : `${filename}.pdf`;

const downloadPdf = (data, filename) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(data);
  link.download = ensurePdfExt(filename);
  link.click();
  window.URL.revokeObjectURL(link.href);
  link.remove();
};

const requestPdf = (url, payload, btn) => {
  const xhr = new XMLHttpRequest();
  const buttonTitle = btn.html();
  const loadingTitle = 'Loading...';

  xhr.open('POST', url, true);
  xhr.responseType = 'blob';
  xhr.setRequestHeader('Accept', 'application/pdf');
  xhr.onreadystatechange = () => {
    // When the request is completed
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 0 || (xhr.status >= 200 && xhr.status < 400)) {
        // If it's successful
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(xhr.response, payload.filename);
        } else {
          downloadPdf(xhr.response, payload.filename);
        }
      } else {
        // Handle errors
        xhr.response
          .text()
          .then(JSON.parse)
          .then(console.error);
      }
    }
  };

  function loadStart() {
    btn.html(loadingTitle).attr('disabled', true);
  }

  function loadEnd() {
    btn.html(buttonTitle).removeAttr('disabled');
  }

  xhr.addEventListener('loadstart', loadStart);
  xhr.addEventListener('loadend', loadEnd);

  xhr.send(JSON.stringify(payload));
};

pdfButton.on('click', e => {
  const btn = $(e.currentTarget);
  const id = btn.attr('data-dom-element');
  const filename = btn.attr('data-filename');
  const baseUrl = btn.attr('data-base-url');

  try {
    const payload = setupPayload(id, filename, baseUrl);
    requestPdf(API_URL, payload, btn);
  } catch (err) {
    console.error(err);
  }
});
