/*
 ***********
 Carousels
 ***********
*/
// The regular bootstrap carousels
const carousels = [
  'slideshow-default-full-width',
  'slideshow-default-standard'
];
const panels = ['slideshow-default-panels'];
const tabs = ['slideshow-default-tableft', 'slideshow-default-tabright'];

// Apply correct styling for the panels, depending on whether they are 5 or 7
const setPanels = (carousel) => {
  const items = carousel.find('.carousel-inner').children('.carousel-item');

  if (items.length === 5) {
    items.addClass('panel-col-2');
  }
};

const togglePanels = (carousel) => {
  const active = carousel.find('.carousel-item.active');
  const panels = carousel.find('.carousel-item');
  const activePanelTitle = active.find('h2');
  const activePanelDescription = active.find('p');
  const activePanelTitleOriginal = activePanelTitle.text();
  const activePanelDescriptionOriginal =
    activePanelDescription.attr('data-truncate') === 'yes' && jQuery().trunk8
      ? activePanelDescription.text()
      : activePanelDescription.text();

  panels.on({
    mouseenter: (e) => {
      const that = $(e.currentTarget);
      const description =
        that.find('p').attr('data-truncate') == 'yes' && jQuery().trunk8 // eslint-disable-line eqeqeq
          ? that.find('p').attr('title')
          : that.find('p').text();
      activePanelTitle.text(that.find('h2').text());
      activePanelDescription.text(description);
    },
    mouseleave: () => {
      activePanelTitle.text(activePanelTitleOriginal);
      activePanelDescription.text(activePanelDescriptionOriginal);
    }
  });
};

const toggleTabs = (carousel) => {
  const items = carousel.find('.carousel-item');
  const toggle = carousel.find('.carousel-item .carousel-caption');
  const up = carousel.find('.carousel-control-prev');
  const down = carousel.find('.carousel-control-next');

  const countTotal = items.length;
  const countVisible = 3;

  if ($(window).width() > 768) {
    carousel.addClass('carousel-fade');
    carousel.carousel('pause');

    toggle.on({
      click: (e) => {
        window.location.href = $(e.currentTarget).find('a.btn').attr('href');
      },
      mouseenter: (e) => {
        const item = $(e.currentTarget).closest('.carousel-item');

        $('.carousel-item', carousel).removeClass('active');
        item.addClass('active');
      }
    });

    if (countTotal <= countVisible) {
      // hide the buttons
      carousel.find('.carousel-control-prev').hide();
      carousel.find('.carousel-control-next').hide();
    } else {
      // attach a class to the items that are visible on the page
      for (let i = 0; i < countVisible; i++) {
        $(items[i]).addClass('is-visible');
      }

      up.show();
      down.hide();
    }
  }

  if ($(window).width() < 768) {
    carousel.removeClass('carousel-fade');
    up.show();
    down.show();
  }

  // scroll the carousel items up or down
  const scrollItems = (direction) => {
    const itemHeight = 130;
    let visible = items.filter('.is-visible');

    items.find('.carousel-caption').css({ top: '1000px' });

    // if direction is up
    if (direction === 'up') {
      const last = visible[countVisible - 1];
      const index = items.index(last);
      const remaining = items.length - index - 1;
      const scrollBy = remaining >= countVisible ? countVisible : remaining;

      // figure out which tabs are now visible on the page
      for (let i = 0; i < scrollBy; i++) {
        $(visible[i]).removeClass('is-visible active');
        $(items[index + 1 + i]).addClass('is-visible');
      }

      visible = items.filter('.is-visible');
      $(visible[1]).addClass('active');

      for (let i = 0; i < visible.length; i++) {
        $(visible[i])
          .find('.carousel-caption')
          .css('top', i * itemHeight);
      }

      // make sure the down button is now visible
      down.show();

      if (remaining <= countVisible) {
        // hide the up button
        up.hide();
      }
    } else {
      const first = visible[0];
      const index = items.index(first);
      const remaining = index;
      const scrollBy = remaining <= countVisible ? remaining : countVisible;

      for (let i = 0; i < scrollBy; i++) {
        $(visible[countVisible - i - 1]).removeClass('is-visible active');
        $(items[index - 1 - i]).addClass('is-visible');
      }

      visible = items.filter('.is-visible');
      $(visible[1]).addClass('active');

      for (let i = 0; i < visible.length; i++) {
        $(visible[i])
          .find('.carousel-caption')
          .css('top', i * itemHeight);
      }

      // make sure the up button is now visible
      up.show();

      if (remaining <= countVisible) {
        // hide the down button
        down.hide();
      }
    }
  };

  up.on('click', (e) => {
    e.preventDefault();
    if ($(window).width() > 768) {
      scrollItems('up');
    }
  });

  down.on('click', (e) => {
    e.preventDefault();
    if ($(window).width() > 768) {
      scrollItems('down');
    }
  });
};

const startCarousels = () => {
  $.each(carousels, (_, c) => {
    const carousel = $(`.${c}`);

    if (carousel.length) {
      carousel.carousel();
    }
  });

  $.each(panels, (_, p) => {
    const carousel = $(`.${p}`);

    setPanels(carousel);
    togglePanels(carousel);
  });

  $.each(tabs, (_, t) => {
    const carousel = $(`.${t}`);

    if (carousel.length) {
      toggleTabs(carousel);

      $(window).resize(() => {
        toggleTabs(carousel);
      });
    }
  });
};

startCarousels();

/*
 ***********
 Add the right rgba color to captions
 ***********
*/

const fullWidthCarousels = $('.slideshow-default-full-width');

const withOpacity = (rgb, opacity) =>
  `${rgb.slice(0, rgb.lastIndexOf(')'))}, ${opacity / 100})`;

const adjustCaptionOpacity = (carousel, opacity) => {
  const captions = carousel.find('.carousel-caption');

  $.each(captions, (_, c) => {
    const $caption = $(c);
    if (!$caption.attr('data-original-color')) {
      $caption.attr('data-original-color', $caption.css('background-color'));
    }

    const themeColor = $caption.attr('data-original-color');

    $caption.css({
      'background-color': withOpacity(themeColor, opacity)
    });
  });
};

const carouselsWithOpacity = (defaultOpacity) => {
  $.each(fullWidthCarousels, (_, c) => {
    const $carousel = $(c);
    let opacity = parseInt($carousel.attr('data-caption-opacity'), 10) || 100;

    if (defaultOpacity) {
      opacity = defaultOpacity;
    }

    if (opacity <= 100) {
      adjustCaptionOpacity($carousel, opacity);
    }
  });
};

const MIN_WIDTH = 768;

if ($(window).width() > MIN_WIDTH) {
  carouselsWithOpacity();
}

$(window).on('resize', () => {
  if ($(window).width() > MIN_WIDTH) {
    carouselsWithOpacity();
  } else {
    carouselsWithOpacity(100);
  }
});
