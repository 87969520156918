/*
 ***********
 Allow the user to go to a specific tab on the page if the tab ID is
 present in the URL
 ***********
*/
const listingResultsContainerClassName = '.listing-results';
const getPanelContainer = panel =>
  panel.children(listingResultsContainerClassName).first();

const getTab = (tabs, id) => {
  const anchor = tabs.find(`a[href="#${id}"][data-toggle="tab"]`);

  return anchor.length ? anchor.closest('li') : false;
};

const activateTab = (tabs, tab) => {
  tabs.children('li').removeClass('active');
  tab.addClass('active');
};

const showTabContent = (container, id) => {
  const element = container.find(`#${id}.tab-pane`);

  if (element) {
    container.children('.tab-pane').removeClass('active');
    element.addClass('active');
  }
};

const tabId = window.location.hash.substring(1);
const tabs = $('.nav.nav-tabs');
const tabContentContainer = $('.tab-content');
let tab;

if (tabs.length && tabContentContainer.length) {
  tab = getTab(tabs, tabId);

  if (tab) {
    activateTab(tabs, tab);
    showTabContent(tabContentContainer, tabId);
  }
}

/*
 ***********
 Add the tab ID to the URL on team pages
 ***********
*/

const attachIdToUrl = e => {
  window.location.hash = $(e.currentTarget)
    .attr('href')
    .substring(1);
};

const tabAnchors = tabs.find('a');

tabAnchors.on('click', attachIdToUrl);

/*
 ***********
 Load a tab's content via ajax
 ***********
*/

const loadMoreButton = $(`.${$.haiku.pagination.loadMoreButtonClass}`);
const pagination = $('ul.pagination');
const ajaxTabs = $('ul.ajax-tabs');

const setCached = el => el.data('cached', true);
const isCached = el =>
  el.data('cached') === true || $.trim(getPanelContainer(el).html());

const toggleActiveTab = (activeTab, panel) => {
  activeTab
    .addClass('active')
    .siblings()
    .removeClass('active');
  panel
    .addClass('active')
    .siblings()
    .removeClass('active');

  window.history.pushState(
    {},
    activeTab.text(),
    activeTab
      .children('a')
      .first()
      .attr('href')
  );
};

// Hide default pagination if the load more button is present
if (loadMoreButton.length) {
  pagination.hide();
}

// Handle the tabs
ajaxTabs.find('a').click(e => {
  e.preventDefault();

  const anchor = $(e.currentTarget);
  const currentTab = anchor.closest('li');
  const panel = $(`#${anchor.data('tab')}`);
  const url = `${anchor.attr('href')}&format=json`;

  if (!isCached(panel)) {
    $.haiku.pagination.toggleLoading(anchor);

    $.getJSON(url)
      .done(({ msg, items, more }) => {
        const container = getPanelContainer(panel);
        $.haiku.pagination.loadMore({
          items,
          container,
          btn: more,
          extras: msg
        });
        setCached(panel);
      })
      .fail((_jqxhr, _textStatus, error) => console.log(error))
      .always(() => {
        toggleActiveTab(currentTab, panel);
        $.haiku.pagination.toggleLoading(anchor);
      });
  } else {
    toggleActiveTab(currentTab, panel);
  }
});
