/*
 ***********
 Wrap iframes in responsive div
 ***********
*/
// Turn img data-mce-json{iframe} into actual iframes. This is a hack for HSD-223. This needs to be removed ASAP.
const imgAttr = 'data-mce-json';
const fakeImages = $('.cover-richtext-tile, .cover-featured-tile')
  .find('img')
  .filter((_, el) => $(el).attr(imgAttr));

if (fakeImages.length) {
  $.each(fakeImages, (_, el) => {
    const img = $(el).hide();
    const json = JSON.parse(img.attr(imgAttr).replace(/'/g, '"'));
    const iframe = $('<iframe />', { src: json.params.src });

    iframe.insertAfter(img);
  });
}
// End piece of code to be removed

const embedClass = 'iframe-container'; // this needs to change for the new bootstrap
const sources =
  '[src*="youtube.com"], [src*="vimeo.com"], [src*="google.com/maps/d/embed?"], [src*="google.com/maps/embed?"], [src*="podcasts.ox.ac.uk"], [src*="maps.ox.ac.uk/embed.html"]';

$('iframe')
  .filter(sources)
  .wrap(`<div class="${embedClass}" />`);
