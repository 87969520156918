/*
 ***********
 Get around the issue with publications modals opening the same content
 ***********
*/
$(document).on('click', 'a[data-toggle="modal"]', e => {
  e.preventDefault();

  const $el = $(e.currentTarget);
  const url = $el.data('href');
  const modal = $($el.data('target'));

  $.ajax({
    type: 'GET',
    url,
    success: result => {
      modal.html(result);
      modal.modal('show');
    }
  });

  modal.on('hidden.bs.modal', () => {
    modal.empty();
    modal.removeData('bs.modal');
  });
});
