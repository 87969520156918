/*
 ***********
 Warn the user when they are trying to change the homepage display
 ***********
*/
const selector =
  '#plone-contentmenu-display ul li a[href*="selectViewTemplate"]';
const changeDisplayLinks = $(selector);

const changeDisplay = e => {
  e.preventDefault();

  const target = $(e.target).attr('href');
  const message =
    'Are you sure you want to make this change? Changing the display will immediately change how the content on this page is displayed to all website users.';

  // eslint-disable-next-line no-alert
  if (window.confirm(message)) {
    window.location.href = target;
  }
};

if (changeDisplayLinks.length) {
  changeDisplayLinks.on('click', changeDisplay);
}
