/*
 ***********
 Show events calendar
 ***********
*/

const calendar = $('#events-calendar');
const eventModal = $('#event-modal');
const eventsApiLink = '@@API/haiku-core-api/events-calendar';
const eventApiLink = '@@modal';

const stripExtrasFromUrl = url => {
  const urlParts = url.split(/[?#]/)[0].split('/');
  // Remove the last part of the URL if it is events_listing_calendar
  return urlParts[urlParts.length - 1] === 'events_listing_calendar'
    ? urlParts.slice(0, urlParts.length - 1).join('/')
    : urlParts.join('/');
};

/**
 * Build the URL for the API based on the window.location.
 * @param  {String}  suffix The API url to append to the window.location.
 * @return {String}         The URL with the correct API appended to it.
 */
const getUrl = suffix =>
  `${stripExtrasFromUrl(window.location.href)}/${suffix}`;

/**
 * Construct the modal content and open it.
 * @param  {Object} modal The jQuery DOM element for the modal.
 * @param  {Object} data  The event data object.
 * @return {void}
 */
const openModal = (modal, data) => {
  modal.empty().append(data);
  modal.modal('show');
};

/**
 * Capture the click event on the event in the calendar.
 * @param  {Object} calEvent The calendar event object.
 * @return {Boolean}         Always return false to prevent the click event.
 */
const eventClick = calEvent => {
  $.ajax({
    url: `${calEvent.url}/${eventApiLink}`
  }).done(data => {
    openModal(eventModal, data);
  });

  return false; // prevent event from being visited
};

const calendarOptions = {
  events: {
    url: getUrl(eventsApiLink),
    type: 'GET',
    error: () => {
      console.error('error fetching!');
    },
    success: data => data.items
  },
  themeSystem: 'bootstrap3',
  header: {
    left: 'prev,next today',
    center: 'title',
    right: 'month,agendaWeek'
  },
  firstDay: 1,
  nowIndicator: true,
  eventClick,
  startParam: 'start',
  endParam: 'end'
};

if (jQuery().fullCalendar) {
  calendar.fullCalendar(calendarOptions);
}
