/*
 ***********
 Change from click to hover on desktop
 ***********
*/

const anchors = $('.nav-link.dropdown-toggle');
/**
 * Detect if the user is using a mobile browser.
 * @return {Boolean} True if it's a mobile browser, False otherwise.
 */
const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

/**
 * Change the click function to a hover for selected elements.
 * @param  {Object} el The jQuery element to apply the dropdownHover to.
 * @return {void}
 */
const changeClickToHover = el => {
  // If we are in a desktop-like environment, let's enable hover
  if (!isMobileDevice() && jQuery().dropdownHover) {
    el.dropdownHover();
  }
};

$.each(anchors, (_idx, el) => changeClickToHover($(el)));
