/*
 ***********
 Add Back To Top Button on selected pages
 ***********
*/

$(document).ready(function () {
  const scrollElement = $('body');
  const multiplier = 2;
  const id = 'haiku-scroll-top';

  // A list of body classes where the button should not be added
  // FIX ME: this needs to go away when we can remove the gototop functionality
  // from the individual plugins, i.e. profiles and list
  // or when we can extend the settings of the pages to allow
  // for disabling the button
  const ignoredClasses = ['section-team', 'section-news', 'section-events'];

  // This is the button we will be adding to the pages
  const scrollTopButton = $('<a>')
    .text('Go to top')
    .attr({
      id,
      class: 'btn btn-sm btn-default',
      href: '#'
    })
    .css({
      position: 'fixed',
      bottom: '0px',
      right: '20px',
      cursor: 'pointer'
    });

  const toggleScrollTopVisibility = () => {
    if ($(window).scrollTop() > $(window).height() * multiplier) {
      scrollTopButton.show();
    } else {
      scrollTopButton.hide();
    }
  };

  const scrollToTop = (e) => {
    e.preventDefault();

    const scrollTop = scrollElement.offset().top;

    $('body, html').animate(
      {
        scrollTop
      },
      1000,
      toggleScrollTopVisibility
    );
  };

  $(document).on('click', `#${id}`, scrollToTop);

  // Figure out if body has one of the ignored classes
  const hasIgnoredBodyClass = (ignored) => {
    const classes = $('body').attr('class');
    const classesArr = typeof classes !== 'undefined' ? classes.split(' ') : [];
    let found = false;

    for (let i = 0; i < classesArr.length; i++) {
      if ($.inArray(classesArr[i], ignored) !== -1) {
        found = true;
        break;
      }
    }

    return found;
  };

  $(document).ready(function () {
    if (!hasIgnoredBodyClass(ignoredClasses)) {
      scrollTopButton.appendTo(scrollElement).hide();
    }
  });

  // Watch the window scroll event
  $(window).on('scroll', toggleScrollTopVisibility);
});
