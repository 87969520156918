// These are specific scripts that should be removed completely
/* global _altmetric_embed_init */

const collapseAccordion = $item => {
  const panelHeading = $item.find('.panel-heading a[data-toggle]');
  const panelContent = $item.find('.panel-collapse');

  if (panelHeading.length) {
    panelHeading.addClass('collapsed');
  }
  if (panelContent.length) {
    panelContent.removeClass('in').attr('aria-expanded', false);
  }
};

// const loadMoreButtonClass = 'load-more-button';
const pagingSelector = '.pagination';
const nextSelector = `${pagingSelector} li.next a`;
const loadButton = $('<a>')
  .addClass($.haiku.pagination.loadMoreButtonClass)
  .html('<span>Load more +</span>');
const holder = $('<div>').addClass('infinite-scroll-button-holder');

/*
 ***********
 Infinite scrolling
 ***********
*/
$(() => {
  const infiniteScroll = '.infinite-scroll';
  // const infiniteScrollDiv = $('.section-publications').find('#site-content-body'); // to be replaced with the class .infinite-scroll

  $(infiniteScroll).each((_, el) => {
    const pagingEl = $(pagingSelector, el);
    const nextButton = $('li.next a', pagingEl);
    const itemSelector = $(el).attr('data-infinite_item');

    if (nextButton.length !== 0) {
      loadButton
        .attr({
          href: $(nextButton).attr('href'),
          class: 'link-button'
        })
        .click(e => {
          // eslint-disable-next-line eqeqeq
          if ($(e.currentTarget).data('loading') == true) {
            return;
          }

          $(e.currentTarget).data('loading', true);
          e.preventDefault();

          $.get(loadButton.attr('href'), data => {
            const $data = $(data);

            const newItems = $(`${infiniteScroll} > ${itemSelector}`, $data);
            $.each(newItems, (_, item) => {
              collapseAccordion($(item));
            });

            const nextURL = $(nextSelector, $data).attr('href');
            if (nextURL === undefined) {
              loadButton.hide();
            } else {
              loadButton.attr('href', nextURL);
            }

            // Load more items
            $.haiku.pagination.loadMore({
              items: newItems,
              container: $(el),
              btn: holder
            });

            // eslint-disable-next-line camelcase
            if (typeof _altmetric_embed_init === 'function') {
              _altmetric_embed_init();
            }

            $(e.currentTarget).data('loading', false);
          });
        });

      pagingEl.hide().after(holder.append(loadButton));
    } else {
      pagingEl.hide(); // hide nav otherwise due to bug in Plone code
    }
  });
});
