/*
 ***********
 Provide pagination for Load more lists
 ***********
*/

if (!$.haiku) {
  $.haiku = {};
}

if (!$.haiku.pagination) {
  $.haiku.pagination = {};
}

$.haiku.pagination.loadMoreButtonClass = 'load-more-button';

$.haiku.pagination.loadMore = function({
  items,
  container,
  btn,
  extras,
  append = true
}) {
  let currentItems;

  if (append) {
    currentItems = container
      .children()
      .not(`.${$.haiku.pagination.loadMoreButtonClass}`);
  }

  container
    .empty()
    .append(currentItems)
    .append(extras)
    .append(items)
    .append(btn);
}

$.haiku.pagination.toggleLoading = function(el, text = 'Loading...') {
  let loading = false;
  let html = text;

  if (!el.data('loading')) {
    const original = el.html();
    loading = true;

    el.data('original-html', original);
  } else {
    html = el.data('original-html');
  }

  el.data('loading', loading).html(html);
};

/*
 ***********
 Listing pagination
 ***********
*/

$(document).on('click', `.${$.haiku.pagination.loadMoreButtonClass}`, function(e) {
  e.preventDefault();

  const btn = $(e.currentTarget);
  const url = btn.attr('href');
  const panel = btn.parent();

  $.haiku.pagination.toggleLoading(btn);

  $.getJSON(url)
    .done(({ items, more, msg }) => {
      $.haiku.pagination.loadMore({
        items,
        container: panel,
        btn: more,
        extras: msg
      });
    })
    .fail((_jqxhr, _textStatus, error) => {
      const errorMsg = $('<p>').addClass('alert alert-danger').html(error);
      $.haiku.pagination.loadMore({
        items: [],
        container: panel,
        extras: errorMsg
      });
    })
    .always(() => {
      $.haiku.pagination.toggleLoading(btn);
    });
});
