/*
 ***********
 Add Skip Navigation Links to the page
 We use this instead of simple adding the <a /> tag to the HTML because we cannot
 figure out why mmenu randomly displaces it. It's very silly and should be fixed!!!
 ***********
*/

function addSkipLinks() {
  const link = $('<a />', {
    class: 'skip-navigation',
    text: 'Skip to main content',
    href: '#site-content'
  });
  $('body').prepend(link);
}

$(document).ready(addSkipLinks);
