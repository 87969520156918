/*
 ***********
 Toggle between texts for collapse/collapsed action buttons
 ***********
*/

const buttons = $('[data-toggle="collapse"]');
const toggleCollapseText = e => {
  const button = $(e.currentTarget);
  const textCollapse = button.attr('data-toggle-collapse');
  const textCollapsed = button.attr('data-toggle-collapsed');

  if (textCollapse && textCollapsed) {
    button.html(button.hasClass('collapsed') ? textCollapse : textCollapsed);
  }
};

buttons.on('click', toggleCollapseText);
