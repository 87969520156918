/*
 ***********
 Create the Table of Contents links using https://ndabas.github.io/toc
 ***********
*/
const dest = $('div.portlet-toc div.well div');
const tocUl = '<ul id="toc"></ul>';
const content = '#content .rich-text';
const scrollTo = target => {
  const scrollTop = $(target).offset().top;
  $('html, body').animate({ scrollTop }, 500);
};

if ($(content) && dest.length && jQuery().toc) {
  const $tocUl = $(tocUl);
  $tocUl.toc({ content, headings: 'h3,h4,h5' });

  // Prevent default
  $tocUl.find('a').on('click', e => {
    e.preventDefault();
    const target = $(e.currentTarget).attr('href');
    scrollTo(target);
  });

  dest.empty().append($tocUl);
}
